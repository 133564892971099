<template>
  <DefaultTemplate>
    <div class="main-slot text-primary pd-5">
      <h3 class="text-center text-primary">
        EXAC เติมเต็มความเป็นเลิศส่งออกไทย <br />เติบโตไกลอย่างยั่งยืน
      </h3>
      <div class="text-center text-grey text-primary mg-t-1">
        Oops! Your're not allowed to access.<br />
        <router-link to="/" class="text-white not-hover"
          ><u>กลับสู่หน้าหลัก</u></router-link
        >
        <br /><el-button type="primary" class="mg-t-2" @click="signout()"
          >เข้าสู่ระบบ</el-button
        >
      </div>
    </div>
  </DefaultTemplate>
</template>
<script>
import DefaultTemplate from "@/template/DefaultTemplate";
import { HTTP } from "@/service/axios";
export default {
  components: {
    DefaultTemplate
  },
  methods: {
    signout() {
      this.fullLoading = true;
      setTimeout(() => {
        window.localStorage.eram.clear();
      }, 200);
      // this.$store.commit("SIGNOUT");
      window.localStorage.removeItem("eram");
      delete HTTP.defaults.headers.common.Authorization;
      //this.$router.push("/");
      setTimeout(() => {
        window.location.href = "/";
      }, 250);
    }
  }
};
</script>

<style scoped>
.main-slot {
  min-height: calc(100vh - 216px);
}
@media (max-width: 767px) {
  .main-slot {
    min-height: calc(100vh - 241px);
  }
}
@media (min-width: 1300px) {
  .main-slot {
    min-height: calc(100vh - 217px);
  }
}
@media (min-width: 1920px) {
  .main-slot {
    min-height: calc(100vh - 216px);
  }
}
</style>